import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="title">        Morse Tutoring
</div>

        <br></br>
        <div className="bio">
        Davey is very good at inspiring bored kids. 
        <br></br><br></br>
        Before raising $1.2m for his public benefit company, coding at Apple, or studying AI at Williams, he was a bored kid, too. 
<br></br><br></br>
        Davey has the greatest impact on students who see no point in math, writing, literature, or physics. 
He helps students find personal connections to school subjects and excel.
<br></br><br></br>
Davey's rate is $290 per hour session. <br></br>Sessions are in person in NYC, <br></br>at your home or a coffee shop.
<br></br><br></br>
For a consult: <br></br>Text (646) 352-2244, or<br></br>
Email <a href="mailto:davey@plexus.earth" className='link'>davey@morsetutoring.com</a>
<br></br>
<br></br>

For more from Davey:<br></br>
<a href="https://daveymorse.gumroad.com/l/school-secrets" className='link'>Lessons</a>&emsp;
<a href="https://www.youtube.com/@morse-tutoring/shorts" className='link'>Videos</a>&emsp;
<a href="https://plexus.earth" className='link'>Plexus</a></div>

<br></br><br></br>

<div className="subtitle">        -- .. ... -.-. .... .. . ..-. <br></br> -- .- -. .- --. . -..
</div>



      </header>
    </div>
  );
}

export default App;
